import { Injectable, EventEmitter, Inject, PLATFORM_ID } from '@angular/core';
import { LocalStorageService } from './local-storage.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';

// Modals
import { InfoModalComponent } from '../directive/info-modal/info-modal.component';
import { AmlKycModalComponent } from '../directive/aml-kyc-modal/aml-kyc-modal.component';
import { RoutingService } from './routing.service';
import { SafeHtmlPipe } from '../pipes/safe-html.pipe';
import { DataService } from './data.service';
import { GoogleAnalitycsService } from './google-analitycs.service';

declare let jQuery: any;
declare let navigator: any;
declare let window: any;
declare let $zopim :any;

@Injectable()

export class GeneralService {
    public disableDepositSubmit = false;
    public tncCloseModalEmitter: EventEmitter<any> = new EventEmitter();
    public savedCardEmitter: EventEmitter<any> = new EventEmitter();
    public depositSetDefaultDataEmitter: EventEmitter<any> = new EventEmitter();
    public showUserModuleEmitter: EventEmitter<any> = new EventEmitter();
    public userModuleMenusEmmiter: EventEmitter<any> = new EventEmitter();
    public withdrawalThresholdAmount: any = null;

    constructor(
        public dialog: MatDialog,
        private localStorageService: LocalStorageService,
        private toastr: ToastrService,
        private _routingService: RoutingService,
        private safeHtmlPipe: SafeHtmlPipe,
        private _dataService: DataService,
        private _googleAnalitycsService: GoogleAnalitycsService,
        @Inject(PLATFORM_ID) private platformId: Object
    ) { }

    getDays() {
        let futuerDays = [];
        for (let i = 1; i <= 31; i++) {
            futuerDays.push(i < 10 ? ("0" + i.toString()) : i.toString());
        }
        return futuerDays;
    }

    getRegEx(): any {
        return {
            charWithUmlauts: '^([a-zA-ZăáäéèêëíîïóôöúûüýlꞏlòçâùÿìãõàßḫģőȁỷčďơșůāaęḍżøñĂÁÄÉÈÊËÍÎÏÓÔÖÚÛÜÝLꞏLÒÇÂÙŸÌÃÕÀSSḪĢŐȀỶČĎƠȘŮĀAĘḌŻØÑ.(), -])*$',
            charWithnumber: '^(?![a-zA-Z-ăáäéèêëíîïóôöúûüýlꞏlòçâùÿìãõàßḫģőȁỷčďơșůāaęḍżøñĂÁÄÉÈÊËÍÎÏÓÔÖÚÛÜÝLꞏLÒÇÂÙŸÌÃÕÀSSḪĢŐȀỶČĎƠȘŮĀAĘḌŻØÑ. ]+$)([0-9A-Za-z-ăáäéèêëíîïóôöúûüýlꞏlòçâùÿìãõàßḫģőȁỷčďơșůāaęḍżøñĂÁÄÉÈÊËÍÎÏÓÔÖÚÛÜÝLꞏLÒÇÂÙŸÌÃÕÀSSḪĢŐȀỶČĎƠȘŮĀAĘḌŻØÑ. ])*$',
            charORnumber: '^([0-9A-Za-z-ăáäéèêëíîïóôöúûüýlꞏlòçâùÿìãõàßḫģőȁỷčďơșůāaęḍżøñĂÁÄÉÈÊËÍÎÏÓÔÖÚÛÜÝLꞏLÒÇÂÙŸÌÃÕÀSSḪĢŐȀỶČĎƠȘŮĀAĘḌŻØÑ. ])*$',
            charOrNumberOrSpachar: '^([0-9A-Za-z-ăáäéèêëíîïóôöúûüýlꞏlòçâùÿìãõàßḫģőȁỷčďơșůāaęḍżøñĂÁÄÉÈÊËÍÎÏÓÔÖÚÛÜÝLꞏLÒÇÂÙŸÌÃÕÀSSḪĢŐȀỶČĎƠȘŮĀAĘḌŻØÑ\/\/.!@#$%&*_ ])*$',
            startNotAllowNumCharOrNumberOrSpachar: /^([0-9/-]+(?:\s\d+[/]\d+)?|\d+[A-Za-z]?|[A-Za-z]+)$/,
            password: /^(?=.*[a-z])(?=.*[0-9])(?=.*[!.:;_*+(),^<=>?'{|}@#$%^&+=~])[a-z0-9!.:;_*+(),^<=>?'{|}@#$%^&+=~]{8,16}$/,
            dob: /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/,
            email: /^[A-Za-z0-9]+((_|-|\+|\.)[A-Za-z0-9]+)*?@[A-Za-z0-9]+(-[A-Za-z0-9]+)*(\.[A-Za-z]{2,6})?(\.[A-Za-z]{2,6})$/,
            charWithUmlautWithSpacial: '^([a-zA-ZăáäéèêëíîïóôöúûüýlꞏlòçâùÿìãõàßḫģőȁỷčďơșůāaęḍżøñĂÁÄÉÈÊËÍÎÏÓÔÖÚÛÜÝLꞏLÒÇÂÙŸÌÃÕÀSSḪĢŐȀỶČĎƠȘŮĀAĘḌŻØÑ.(),\/. -])*$',
        };
    }

    getMonths() {
        let futuerMonths = [];
        for (let i = 1; i <= 12; i++) {
            futuerMonths.push(i < 10 ? ("0" + i.toString()) : i.toString());
        }
        return futuerMonths;
    }

    getYears() {
        let dateObj = new Date();
        let startYear = dateObj.getFullYear();
        let endYear = dateObj.getFullYear() + 30;
        let futureYears = [];

        for (let i = startYear; i <= endYear; i++) {
            futureYears.push(i);
        }
        return futureYears;
    }

    getFromToYears() {
        let dateObj = new Date();
        let startYear = dateObj.getFullYear();
        let endYear = dateObj.getFullYear() - 2;
        let futureYears = [];
        for (let i = startYear; i > endYear; i--) {
            futureYears.push(i);
        }
        return futureYears;
    }

    getBirthYears(age: any) {
        let dateObj = new Date();
        let startYear = dateObj.getFullYear() - age;
        let endYear = dateObj.getFullYear() - 100;
        let pastYears = [];
        for (let i = startYear; i >= endYear; i--) {
            pastYears.push(i);
        }
        return pastYears;
    }

    savedCardEmitterEvent() {
        this.savedCardEmitter.next('loadSavedCards');
    }

    depositSetDefaultDataEvent() {
        this.depositSetDefaultDataEmitter.next('loadDepositDefaultData');
    }

    keyPressWithZero(event: any) {
        let ctl = document.getElementById('phoneNumber');
        let startPos = ctl['selectionStart'];

        if (startPos == 0 && String.fromCharCode(event.which) == '0') {
            event.preventDefault();
            return false
        }
        const pattern = /^[0-9]*$/;
        const inputChar = String.fromCharCode(event.charCode);
        if (event.keyCode !== 8 && event.keyCode !== 46 && !pattern.test(inputChar)) {
            event.preventDefault();
        }
    }
    
    infomodel(title: string, msg: string, okBtn: any = null, cancelBtn: any = null, modelName: string = 'infoModel', formData: any = {}) {
        return this.dialog.open(InfoModalComponent, {
            backdropClass: 'popupBackdropClass',
            data: {
                msgTitle: title,
                msgBody: msg,
                msgOkBtn: okBtn,
                msgCancelBtn: cancelBtn,
                modelName: modelName,
                formData: formData
            },
            disableClose: true
        });
    }

    openWithadrawalThresholdModal(thresholdAmount: any = 0) {
        this.setWithdrawalThresholdAmount(thresholdAmount);
        const thresholdModal = this.infomodel('', '', '', '', 'withdrawalThresholdReachedModal');
        thresholdModal.afterClosed().subscribe(result => {
            if (result) {
                this._routingService.altRouterLink('user/payout');
            }
        });
    }

    setWithdrawalThresholdAmount(val: any) {
        this.withdrawalThresholdAmount = val;
    }
    getWithdrawalThresholdAmount(): any {
        return this.withdrawalThresholdAmount;
    }

    isJsonFormate(str: any) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    amlKycmodal() {
        return this.dialog.open(AmlKycModalComponent, {
            data: {
            },
            disableClose: true
        });
    }

    checkPlayerBanned() {
        let playerFlag: any = this.localStorageService.get('playerFlag');;
        if (playerFlag && (playerFlag.gamblingAddicted || playerFlag.moneyLaundering || playerFlag.fraud)) {
            let infoMdel = this.infomodel('Banned', '', 'Help', null, 'playerBannedModal');
            return true;
        }
        return false;
    }

    checkConfirmedEmail() {
        let playerFlag: any = this.localStorageService.get('player');
        if (playerFlag && !playerFlag.playerEmailVerified) {
            let confirmModal = this.infomodel('', '', '', null, 'checkPlayerConfirmEmail');
            return confirmModal;
        }
        return false;
    }

    checkConfirmKycLevel() {
        let playerFlag: any = this.localStorageService.get('playerFlag');
        if (playerFlag && (playerFlag.playerKycLevel <= 1)) {
            let confirmModal = this.infomodel('', '', '', null, 'checkConfirmKycLevel');
            return confirmModal;
        }
        return false;
    }

    checkAmlKyc() {
        let playerFlag: any = this.localStorageService.get('playerFlag');;
        if (playerFlag && playerFlag.amlFlag) {
            this.amlKycmodal();
            return true;
        }
        return false;
    }

    setTouchLink(assets: any) {
        let appleTouchIconEle = document.getElementById('touchIconId');
        let webIconEle = document.getElementById('androidIconId');
        let touch = assets.digital.touch_icon;

        if (!appleTouchIconEle) {
            let link: any = document.createElement('link');
            link.id = 'touchIconId';
            link.rel = 'apple-touch-icon';
            link.sizes = '180x180'
            link.href = touch;
            document.head.appendChild(link);
        } else {
            appleTouchIconEle.setAttribute("href", touch);
        }

        if (!webIconEle) {
            let link: any = document.createElement('link');
            link.id = 'androidIconId';
            link.rel = 'icon';
            link.sizes = '192x192'
            link.href = touch;
            document.head.appendChild(link);
        } else {
            webIconEle.setAttribute("href", touch);
        }
    }

    addCustomCssViaAws() {
        if (isPlatformBrowser(this.platformId)) {
            let styles = document.createElement('link');
            styles.rel = 'stylesheet';
            styles.type = 'text/css';
            styles.media = 'screen';
            styles.href = 'https://spielbank.dmcgroup.eu/custom_style.css';
            document.getElementsByTagName('head')[0].appendChild(styles);
        }
    }

    setPWAMainfestLink() {
        if (isPlatformBrowser(this.platformId)) {
            let isClient = this.localStorageService.get('isClient');
            let linkEle = document.getElementById('manifestLink');
            let href = 'assets/pwa/' + isClient + '/manifest.webmanifest';
            if (!linkEle) {
                let link: any = document.createElement('link');
                link.id = 'manifestLink';
                link.rel = 'manifest';
                link['crossorigin'] = 'use-credentials';
                link.href = href;
                document.head.appendChild(link);
            } else {
                linkEle.setAttribute("href", href);
            }
        }
    }

    setCommonJsLink() {
        if (isPlatformBrowser(this.platformId)) {
            const linkEle = document.getElementById('commonJsLink');
            const env = this.localStorageService.get('clientEnv');
            let src = 'assets/js/spielbank/client-common.js';
            if (env === 'production') {
                src = 'assets/js/spielbank/client-common-prod.js';
            }
            if (!linkEle) {
                const ele: any = document.createElement('script');
                ele.src = src;
                ele.type = 'text/javascript';
                ele.async = true;
                ele.id = 'commonJsscriptEle';
                ele.charset = 'utf-8';
                document.getElementsByTagName('head')[0].appendChild(ele);
            } else {
                linkEle.setAttribute('src', src);
            }
        }
    }

    cookieBotScriptLink() {
        let url = window.location.href;
        if (!url.includes('localhost')) {
            const linkEle = document.getElementById('Cookiebot');
            if (!linkEle) {
                const ele: any = document.createElement('script');
                ele.id = 'Cookiebot';
                ele.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
                ele.setAttribute('id', 'Cookiebot');
                ele.setAttribute('data-cbid', '1cbcfaeb-6a28-4105-afc4-289c4bf43a12');
                ele.setAttribute('type', 'text/javascript');
                ele.setAttribute('async', true);
                document.getElementsByTagName('head')[0].appendChild(ele);
            } else {
                linkEle.setAttribute('src', 'https://consent.cookiebot.com/uc.js');
            }
        }
    }

    marketingCookieJsLink() {
        const linkEle = document.getElementById('marketingCookieBot');
        if (!linkEle) {
            const ele: any = document.createElement('script');
            ele.id = 'marketingCookieBot';
            ele.setAttribute('src', '//widget.manychat.com/1234567891011.js');
            ele.setAttribute('type', 'text/plain');
            ele.setAttribute('id', 'marketingCookieBot');
            ele.setAttribute('data-cookieconsent', 'marketing');
            document.getElementsByTagName('head')[0].appendChild(ele);
        } else {
            linkEle.setAttribute('src', '//widget.manychat.com/1234567891011.js');
        }
    }

    setCookieDeclarationLink() {
        const ele: any = document.createElement('script');
        ele.src = 'https://consent.cookiebot.com/1cbcfaeb-6a28-4105-afc4-289c4bf43a12/cd.js';
        ele.type = 'text/javascript';
        ele.async = true;
        ele.id = 'CookieDeclaration';
        if (isPlatformBrowser(this.platformId)) {
            jQuery('#cookieDeclarationEle').append(ele);
        }
    }

    toastSuccess(toastMsg: string = '', toastTitle: string = '', toastOption: any = { closeButton: true, timeOut: 6000, extendedTimeOut: 5000 }) {
        let headerPageAssets: any = this._dataService.getAssets('headerPage');
        if (!toastMsg && headerPageAssets) {
            toastMsg = headerPageAssets.errormsg.headerPage_success_msg_text;
        }

        if (!toastTitle && headerPageAssets) {
            toastTitle = headerPageAssets.errormsg.headerPage_success_msg_title;
        }
        this.toastr.success(this.safeHtmlPipe.transform(toastMsg), this.safeHtmlPipe.transform(toastTitle), toastOption);
    }

    toastError(toastMsg: string = '', toastTitle: string = '', toastOption: any = { closeButton: true, timeOut: 6000, extendedTimeOut: 5000 }) {
        let headerPageAssets: any = this._dataService.getAssets('headerPage');
        if (!toastMsg && headerPageAssets) {
            toastMsg = headerPageAssets.errormsg.headerPage_error_msg_text;
        }

        if (!toastTitle && headerPageAssets) {
            toastTitle = headerPageAssets.errormsg.headerPage_error_msg_title;
        }
        this.toastr.error(this.safeHtmlPipe.transform(toastMsg), this.safeHtmlPipe.transform(toastTitle), toastOption);
    }

    toastWarning(toastMsg: string = '', toastTitle: string = '', toastOption: any = { closeButton: true, timeOut: 6000, extendedTimeOut: 5000 }) {
        let headerPageAssets: any = this._dataService.getAssets('headerPage');
        if (!toastMsg && headerPageAssets) {
            toastMsg = headerPageAssets.errormsg.headerPage_warning_msg_text;
        }

        if (!toastTitle && headerPageAssets) {
            toastTitle = headerPageAssets.errormsg.headerPage_warning_msg_title;
        }
        this.toastr.warning(this.safeHtmlPipe.transform(toastMsg), this.safeHtmlPipe.transform(toastTitle), toastOption);
    }

    toastInfo(toastMsg: string = '', toastTitle: string = '', toastOption: any = { closeButton: true, timeOut: 6000, extendedTimeOut: 5000 }) {
        let headerPageAssets: any = this._dataService.getAssets('headerPage');
        if (!toastMsg && headerPageAssets) {
            toastMsg = headerPageAssets.errormsg.headerPage_information_msg_text;
        }

        if (!toastTitle && headerPageAssets) {
            toastTitle = headerPageAssets.errormsg.headerPage_information_msg_title;
        }
        this.toastr.info(this.safeHtmlPipe.transform(toastMsg), this.safeHtmlPipe.transform(toastTitle), toastOption);
    }

    showUseModule(isModelShow: boolean, arg: string = 'account') {
        let userMenuObj = {
            isModelShow: isModelShow,
            pageName: arg
        };
        this.showUserModuleEmitter.emit(userMenuObj);
    }

    showUserModuleMenus(arg: boolean = true) {
        this.userModuleMenusEmmiter.emit(arg);
    }

    isIOSDevice() {
        console.log('navigator---', navigator.userAgent);
        if (navigator.userAgent.match(/iPhone|iPad|iPod|Mac|Macintosh/i)) {
            return true;
        } else {
            return false;
        }
    }

    redirectToPage() {
        let me = this;
        if (isPlatformBrowser(this.platformId)) {
            const loginEle = jQuery('.redirect-login');
            if (loginEle.length) {
                loginEle.click(() => {
                    me.redirectTORouting('login');
                });
            }

            const regEle = jQuery('.redirect-register');
            if (regEle.length) {
                regEle.click(() => {
                    me.redirectTORouting('register');
                });
            }

            const fpEle = jQuery('.redirect-forgot-password');
            if (fpEle.length) {
                fpEle.click(() => {
                    jQuery('.modal').modal('hide');
                    me.redirectTORouting('forgot-password');
                });
            }

            const aboutEle = jQuery('.redirect-about');
            if (aboutEle.length) {
                aboutEle.click(() => {
                    me.redirectTORouting('about');
                });
            }

            const gamePlayEle = jQuery('.redirect-game-play');
            if (gamePlayEle.length) {
                gamePlayEle.click(() => {
                    me.redirectTORouting('game-play');
                });
            }

            const gameListEle = jQuery('.redirect-game-list');
            if (gameListEle.length) {
                gameListEle.click(() => {
                    me.redirectTORouting('game-list');
                });
            }

            const contactEle = jQuery('.redirect-contact');
            if (contactEle.length) {
                contactEle.click(() => {
                    me.redirectTORouting('contact');
                });
            }

            const imprintEle = jQuery('.redirect-imprint');
            if (imprintEle.length) {
                imprintEle.click(() => {
                    me.redirectTORouting('imprint');
                });
            }

            const tncEle = jQuery('.redirect-terms-and-conditions');
            if (tncEle.length) {
                tncEle.click(() => {
                    me.redirectTORouting('terms-and-conditions');
                });
            }

            const supportEle = jQuery('.redirect-support');
            if (supportEle.length) {
                supportEle.click(() => {
                    me.redirectTORouting('support');
                });
            }

            const safetyEle = jQuery('.redirect-safety');
            if (safetyEle.length) {
                safetyEle.click(() => {
                    me.redirectTORouting('safety');
                });
            }

            const marketingProfilingEle = jQuery('.redirect-marketing-profiling');
            if (marketingProfilingEle.length) {
                marketingProfilingEle.click(() => {
                    me.redirectTORouting('marketing-profiling');
                });
            }

            const bonusTermsEle = jQuery('.redirect-bonus-terms');
            if (bonusTermsEle.length) {
                bonusTermsEle.click(() => {
                    me.redirectTORouting('bonus-terms');
                });
            }

            const cpEle = jQuery('.redirect-cookie-policy');
            if (cpEle.length) {
                cpEle.click(() => {
                    me.redirectTORouting('cookie-policy');
                });
            }

            const brEle = jQuery('.redirect-betting-rules');
            if (brEle.length) {
                brEle.click(() => {
                    me.redirectTORouting('betting-rules');
                });
            }

            const pnEle = jQuery('.redirect-privacy-notice');
            if (pnEle.length) {
                pnEle.click(() => {
                    me.redirectTORouting('privacy-notice');
                });
            }

            const faqEle = jQuery('.redirect-faq');
            if (faqEle.length) {
                faqEle.click(() => {
                    me.redirectTORouting('faq');
                });
            }

            const resGameingEle = jQuery('.redirect-responsible-gaming');
            if (resGameingEle.length) {
                resGameingEle.click(() => {
                    me.redirectTORouting('responsible-gaming');
                });
            }

            const dataProtectionEle = jQuery('.redirect-data-protection');
            if (dataProtectionEle.length) {
                dataProtectionEle.click(() => {
                    me.redirectTORouting('data-protection');
                });
            }

            const searchEle = jQuery('.redirect-search');
            if (searchEle.length) {
                searchEle.click(() => {
                    me.redirectTORouting('search');
                });
            }

            const bonusEle = jQuery('.redirect-bonus');
            if (bonusEle.length) {
                bonusEle.click(() => {
                    me.redirectTORouting('bonus');
                });
            }

            const categoriesEle = jQuery('.redirect-categories');
            if (categoriesEle.length) {
                categoriesEle.click(() => {
                    me.redirectTORouting('categories');
                });
            }

            const sportBookEle = jQuery('.redirect-sport-book');
            if (sportBookEle.length) {
                sportBookEle.click(() => {
                    me.redirectTORouting('sport-book');
                });
            }

            const userEle = jQuery('.redirect-user');
            if (userEle.length) {
                userEle.click(() => {
                    me.redirectTORouting('user/account');
                });
            }

            const selfBlockEle = jQuery('.redirect-selfblock');
            if (selfBlockEle.length) {
                selfBlockEle.click(() => {
                    me.redirectTORouting('user/self-block');
                });
            }

            const zenDeskChat = jQuery('.openChat');
            if (zenDeskChat.length) {
                zenDeskChat.click(() => {
                    $zopim.livechat.window.show();
                    me._googleAnalitycsService.trackingUserAction('event', 'use_chat');
                });
            }
        }
    }

    redirectTORouting(pageName: string) {
        this.dialog.closeAll();
        if (isPlatformBrowser(this.platformId)) {
            jQuery('.modal').modal('hide');
        }
        this._routingService.altRouterLink(pageName);
        if (isPlatformBrowser(this.platformId)) {
            window.scrollTo(0, 0);
        }
    }

    downloadFile(url: string, fileName: string) {
        var link = document.createElement('a');
        link.href = url;
        link.download = fileName;
        link.dispatchEvent(new MouseEvent('click'));
    }

    pdfDownload(res: any, fileName: any, fileType: string = 'application/pdf', fileExt: string = '.pdf') {
        try {
            let bytes = new Uint8Array(res.length);
            for (var i = 0; i < res.length; i++) {
                bytes[i] = res[i];
            }

            let blob = new Blob([bytes], { type: fileType });
            let link = document.createElement('a');
            link.href = window.URL.createObjectURL(blob);
            let tmpFileName = fileName;
            link.download = tmpFileName;
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        } catch (e) {
            console.log(e);
        }
    }

    getLugasErrorMessage(assets: any, code: any) {
        switch (code) {
            case 990002:
                return assets.errormsg.lugasRulesPage_error_has_not_yet_been_registered;
            case 990005:
                return assets.errormsg.lugasRulesPage_error_memory_error_has_occurred;
            case 991005:
                return assets.errormsg.lugasRulesPage_error_player_already_registered;
            case 991002:
                return assets.errormsg.lugasRulesPage_error_player_already_registered_for_specified_playerid;
            case 991003:
                return assets.errormsg.lugasRulesPage_error_limit_must_be_set_for_first_time_registerig_player;
            case 991010:
                return assets.errormsg.lugasRulesPage_error_deposit_exceeds_the_remaining_budget;
            case 991021:
                return assets.errormsg.lugasRulesPage_error_player_is_inactive;
            case 991022:
                return assets.errormsg.lugasRulesPage_error_player_is_in_waiting_period_and_cannot_be_placed_active_yet;
            case 991023:
                return assets.errormsg.lugasRulesPage_error_player_already_with_current_provider_aktiv;
            case 990024:
                return assets.errormsg.lugasRulesPage_error_player_data_not_correct;
            case 991025:
                return assets.errormsg.lugasRulesPage_error_player_active_with_another_provider;
            case 991026:
                return assets.errormsg.lugasRulesPage_error_player_already_active;
            case 990004:
                return assets.errormsg.lugasRulesPage_error_technical_error_F0004;
            case 990003:
                return assets.errormsg.lugasRulesPage_error_max_limit_error;
            case 990006:
                return assets.errormsg.lugasRulesPage_error_technical_error_F0006;
            case 991111:
                return assets.errormsg.lugasRulesPage_error_technical_error_F1111;
            case 991030:
                return assets.errormsg.lugasRulesPage_error_technical_error_data_validation_error;
        }
    }

    getBrowserTabId() {
        try {
            if (isPlatformBrowser(this.platformId)) {
                if (window.IsDuplicate()) {
                    this.dialog.closeAll();
                    setTimeout(() => {
                        let msg = 'Wir haben einen zweiten Tab festgestellt. Aus rechtlichen Gründen ist das Spielen in einem zweiten Tab nicht möglich. Bitte schließe diesen Tab hier und setze Dein Spiel im ersten Tab fort.';
                        let title = 'Fehler';
                        let okBtn = 'Ok';
                        let homePageAssets: any = this._dataService.getAssets('homePage') || {};
                        if (homePageAssets && homePageAssets.errormsg) {
                            msg = homePageAssets.errormsg.homePage_launch_error_duplicate_tab;
                            title = homePageAssets.errormsg.homePage_game_error_title;
                            okBtn = homePageAssets.errormsg.homePage_common_ok_btn;
                        }
                        this.infomodel(title, msg, okBtn);
                    }, 1000);
                    return true;
                }
            }
        } catch (e) {
            console.log('Exception caught getBrowserTabId');
        }

        return false;
    }
}
