
import { BrowserModule, provideClientHydration } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgModule } from '@angular/core';
import { MatDialogModule } from '@angular/material/dialog';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
// import { TransferHttpCacheModule } from '@nguniversal/common'; // HERE
import { RxStompService } from './services/rx-stomp.service';

import { LoaderModule } from './directive/loader/loader.module';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './services/shared.module';
import { HeaderModule } from './component/header/header.module';
import { MainFooterComponent } from './component/footer/main-footer/main-footer.component';
import { NavBottomComponent } from './component/nav-bottom/nav-bottom.component';

import { InfoModalComponent } from './directive/info-modal/info-modal.component';
import { LugasLimitModalComponent } from './directive/lugas-limit-modal/lugas-limit-modal.component';
import { VerificationModalComponent } from './directive/verification-modal/verification-modal.component';
import { TransactionOverviewModalComponent } from './directive/transaction-overview-modal/transaction-overview-modal.component';
import { APICacheInterceptor } from './cache-interceptor/api-cache.interceptor';

@NgModule({
  declarations: [
    AppComponent,
    InfoModalComponent,
    MainFooterComponent,
    LugasLimitModalComponent,
    VerificationModalComponent,
    NavBottomComponent,
    TransactionOverviewModalComponent
  ],

  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    SharedModule.forRoot(),
    AppRoutingModule,
    LoaderModule,
    HeaderModule,
    MatDialogModule,
    NgIdleKeepaliveModule.forRoot(),
    // TransferHttpCacheModule
  ],
  providers: [
    RxStompService
    // { provide: HTTP_INTERCEPTORS, useClass: APICacheInterceptor, multi: true },
    // { provide: 'serverResponse', useValue: {} }
    // provideClientHydration() 
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
